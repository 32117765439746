import React, { useRef, useEffect } from "react";
import {
  TableBody,
  TableRow,
  TableCell,
  Badge,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { recrearVenta } from "./utils";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import useClienteDescuentoPlazo from "../../../customHooks/useClienteDescuentoPlazo";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";

export default function BodyTablaFactura({
  items,
  selectionList,
  verDetalle,
  printPdf,
  anularVenta,
  generarPdf,
  setMontos,
  suggestions,
  disabledSinRecargo,
  disabledRecargo,
  formPost,
  devolucion,
  ventas,
  abrirModalFacturarATerceros,
  handleOpenModalPdf,
  handleModalFechaVencimiento,
  toggleModalComprobanteAFactura,
  handleReCrearVenta,
  handleSendEmailVenta,
  handleOpenModalImprimirComprobante,
  toggleModalValidate,
  handleOpenModalSeleccionar,
}) {
  const didMountRef = useRef(false);
  const { perfil, empleado, aplica_impuesto } = useSelector(
    (state) => state.loginReducer,
  );
  const isVendedor =
    perfil === "Empleado" || perfil === "Vendedor" ? true : false;
  const { form, setForm } = formPost;
  const [arrayexistPdf, setarrayexistPdf] = React.useState([]);
  const { selection, setSelection } = selectionList;
  const { disabledSelectRecargo, setDisabledSelectRecargo } = disabledRecargo;
  const { disabledSelectSinRecargo, setDisabledSelectSinRecargo } =
    disabledSinRecargo;
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { comprobante_pdf } = useSelector((store) => store.pDF);
  const { setNewClient } = useClienteDescuentoPlazo();

  const handleChangeSelection = (item) => {
    let subM = 0,
      desM = 0;
    let selected = suggestions.find((x) => x.idVenta === item.id);
    let form_ = [];
    //Primero se fija si la venta ya esta seleccionada
    if (selection.includes(item.id)) {
      let index = selection.indexOf(item.id);
      let newSelection = [...selection];
      let newForm = [...form];
      newSelection.splice(index, 1);
      newForm.splice(index, 1);
      //Quita la venta de la lista de ventas seleccionadas
      setSelection(newSelection);
      //Quita la venta de la lista de form (se utiliza para el post)
      setForm(newForm);
      subM = Number(setMontos.newMontos.subtotal) - selected.deuda;
      desM =
        Number(setMontos.newMontos.descuento) -
        (selected.recargo_cc === null
          ? 0
          : selected.recargo_cc.monto < selected.deuda
            ? selected.deuda - selected.recargo_cc.monto || 0
            : 0);
    } else {
      //Si no esta seleccionada, se agrega a la lista de ventas seleccionadas y al form
      setSelection([...selection, item.id]);
      setForm([
        ...form,
        {
          venta_id: selected.idVenta,
          deuda: selected.deuda,
          descuento:
            selected.recargo_cc === null
              ? 0
              : selected.recargo_cc.monto < selected.deuda
                ? (selected.deuda - selected.recargo_cc.monto).toFixed(2)
                : 0,
          monto_pagar:
            selected.recargo_cc === null ? 0 : selected.recargo_cc.monto,
          fechaHoraVenta: selected.fechaHoraVenta,
          nroFactura: selected.nro_factura,
        },
      ]);
      subM = Number(setMontos.newMontos.subtotal) + selected.deuda;

      desM =
        Number(setMontos.newMontos.descuento) +
        (selected.recargo_cc === null
          ? 0
          : selected.recargo_cc.monto < selected.deuda
            ? selected.deuda - selected.recargo_cc.monto || 0
            : 0);
    }
    setMontos.setNewMontos((prev) => ({
      ...prev,
      subtotal: Number.isInteger(subM) ? subM : subM.toFixed(2),
      descuento: Number.isInteger(desM) ? desM : desM.toFixed(2),
    }));
    validateTipoVenta(selected);
  };

  const validateTipoVenta = (selected) => {
    if (selected.recargo_cc !== null) setDisabledSelectSinRecargo(true);
    else setDisabledSelectRecargo(true);
  };

  useEffect(() => {
    if (selection.length === 0) {
      setDisabledSelectRecargo(false);
      setDisabledSelectSinRecargo(false);
    }
  }, [selection]);

  useEffect(() => {
    if (selection.length === 0) {
      setDisabledSelectRecargo(false);
      setDisabledSelectSinRecargo(false);
    }
  }, [selection]);

  const disabledItem = (item) => {
    let disabled = false;

    let selected = suggestions.find((x) => x.idVenta === item.id);
    //disabledSelectRecargo determina que no hay ventas con recargo por plazos seleccionadas y que se deben deshabilitar las ventas que tienen recargo por plazos
    //Si esto pasa y la venta que se quiere seleccionar tiene recargo por plazos, se deshabilita
    if (disabledSelectRecargo && selected.recargo_cc !== null) {
      disabled = true;
    }
    //disabledSelectSinRecargo determina que no hay ventas sin recargo por plazos seleccionadas y que se deben deshabilitar las ventas que no tienen recargo por plazos
    //Si esto pasa y la venta que se quiere seleccionar no tiene recargo por plazos, se deshabilita
    if (disabledSelectSinRecargo && selected.recargo_cc === null) {
      disabled = true;
    }
    return disabled;
  };

  const existPdfToPrint = async () => {
    let newArray = [];

    if ((await items.length) > 0) {
      await items.map((x, index) => {
        for (let i = 0; i < comprobante_pdf.length; i++) {
          if (comprobante_pdf[i])
            if (x.id === comprobante_pdf[i].detalle[0].idVenta) {
              newArray.push(x.id);
            }
        }
      });
    }
    setarrayexistPdf(newArray);
  };

  React.useEffect(() => {
    if (didMountRef.current) {
      existPdfToPrint();
    } else {
      existPdfToPrint();
      didMountRef.current = true;
    }
  }, comprobante_pdf);

  return (
    <TableBody>
      {items && items.length > 0
        ? items.map((item, index) => {
            let tempItem = { ...item };
            delete tempItem.id;
            delete tempItem.tipoReferencia;
            delete tempItem.tipo_comprobante;
            delete tempItem.tipo_factura;
            delete tempItem.montoTotal;
            delete tempItem.total_nc;
            delete tempItem.has_factura;
            delete tempItem.is_validated;
            delete tempItem.is_agrupada;
            const validateFcemA =
              item.montoTotal < 500000 || item.total_nc === 0;

            return (
              <TableRow key={index} className="mt-3" hover>
                <TableCell style={{ minWidth: 110, padding: 0 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {disabledItem(item) ? (
                      <TooltipWithoutIcon
                        placement={"top"}
                        title={
                          <h6
                            style={{
                              fontSize: 15,
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            {/* Si disabledSelectRecargo se deshabilitan las ventas con recargo */}
                            {disabledSelectRecargo
                              ? "No se puede seleccionar ya que es una venta con descuentos por plazo y existen ventas seleccionadas sin descuento por plazo"
                              : "No se puede seleccionar ya que es una venta sin descuentos por plazo y existen ventas seleccionadas con descuento por plazo"}
                          </h6>
                        }
                      >
                        <span>
                          <Checkbox
                            color="primary"
                            disabled={true}
                            checked={selection.includes(item.id)}
                            className="fontBold"
                          />
                        </span>
                      </TooltipWithoutIcon>
                    ) : (
                      <Checkbox
                        color="primary"
                        checked={selection.includes(item.id)}
                        onChange={() => {
                          !disabledItem(item) && handleChangeSelection(item);
                        }}
                        className="fontBold"
                      />
                    )}

                    {items[index].tipo_comprobante === "FCEM A"
                      ? "FCEM A"
                      : item.tipoReferencia}
                  </div>
                </TableCell>
                {Object.values(tempItem).map((value, index) => {
                  if (index === 2) {
                    return (
                      <TableCell
                        key={index}
                        align={"center"}
                        style={{
                          maxWidth: 230,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={index}
                        align={"center"}
                        style={{
                          maxWidth: 200,
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  }
                })}
                <TableCell align={"center"}>
                  <Badge
                    color="secondary"
                    invisible={!arrayexistPdf.includes(item.id)}
                    badgeContent={arrayexistPdf.includes(item.id) ? 1 : 0}
                  >
                    <MenuB>
                      <MenuItem onClick={() => verDetalle(item.id)}>
                        Ver detalle
                      </MenuItem>
                      {!isVendedor &&
                        item.tipoReferencia.props.children[0] === "FA" && (
                          <MenuItem
                            onClick={() => {
                              handleModalFechaVencimiento(item);
                            }}
                          >
                            Cambiar fecha de vencimiento
                          </MenuItem>
                        )}
                      {items[index].tipo_comprobante !== "FCEM A" &&
                        item.tipoReferencia.props.children[0] === "CI" &&
                        !aplica_impuesto &&
                        validateFcemA && (
                          <MenuItem
                            onClick={() => {
                              toggleModalComprobanteAFactura(ventas[index]);
                            }}
                          >
                            Generar factura
                          </MenuItem>
                        )}
                      {!ventas[index].nota_credito ? (
                        <MenuItem
                          onClick={() => {
                            anularVenta(ventas[index]);
                          }}
                        >
                          Anular
                        </MenuItem>
                      ) : null}
                      {items[index].has_factura &&
                        !items[index].is_validated &&
                        !aplica_impuesto && (
                          <MenuItem
                            onClick={() => {
                              toggleModalValidate(items[index]);
                            }}
                          >
                            Generar CAE AFIP
                          </MenuItem>
                        )}
                      {items[index].tipo_comprobante !== "FCEM A" &&
                        item.tipoReferencia.props.children[0] === "CI" &&
                        !aplica_impuesto && (
                          <MenuItem
                            onClick={() => {
                              abrirModalFacturarATerceros(
                                ventas[index],
                                ventas[index].montoTotal,
                              );
                            }}
                          >
                            Facturar a terceros
                          </MenuItem>
                        )}
                      {items[index].tipo_comprobante.nombre !== "FCEM A" ? (
                        <MenuItem
                          onClick={() =>
                            handleOpenModalImprimirComprobante(ventas[index])
                          }
                        >
                          Imprimir Comprobante
                        </MenuItem>
                      ) : null}
                      {ventas[index].nota_credito && (
                        <MenuItem
                          onClick={() =>
                            handleOpenModalSeleccionar(ventas[index])
                          }
                        >
                          {ventas[index].has_factura
                            ? "Imprimir Nota de Crédito"
                            : "Imprimir Devolución"}
                        </MenuItem>
                      )}
                      {items[index].tipo_comprobante.nombre !== "FCEM A" ? (
                        <MenuItem
                          onClick={() => {
                            handleSendEmailVenta(item.id);
                          }}
                        >
                          Enviar EMAIL
                        </MenuItem>
                      ) : null}

                      {items[index].tipo_comprobante !== "FCEM A"
                        ? items[index].has_factura &&
                          items[index].is_validated &&
                          !items[index].is_agrupada && (
                            <MenuItem
                              onClick={() => {
                                devolucion(item.id, "N");
                              }}
                            >
                              Generar Nota de Crédito Parcial
                            </MenuItem>
                          )
                        : null}
                      {items[index].tipo_comprobante !== "FCEM A"
                        ? !items[index].has_factura && (
                            <MenuItem
                              onClick={() => {
                                devolucion(item.id, "D");
                              }}
                            >
                              Generar Devolución
                            </MenuItem>
                          )
                        : null}
                      {items[index].tipo_comprobante !== "FCEM A" ? (
                        <MenuItem onClick={() => handleOpenModalPdf(item.id)}>
                          Enviar por WhatsApp
                        </MenuItem>
                      ) : null}
                      {items[index].tipo_comprobante !== "FCEM A" ? (
                        <MenuItem
                          onClick={() => {
                            recrearVenta(
                              handleReCrearVenta,
                              setNewClient,
                              ventas[index],
                            );
                          }}
                        >
                          Recrear venta
                        </MenuItem>
                      ) : null}
                      {configGeneral.en_ticket &&
                        arrayexistPdf.includes(item.id) && (
                          <MenuItem onClick={() => printPdf(item.id, "ticket")}>
                            Imprimir Recibo Conciliación - Ticket
                          </MenuItem>
                        )}
                      {configGeneral.en_a4 &&
                        arrayexistPdf.includes(item.id) && (
                          <MenuItem onClick={() => printPdf(item.id, "A4")}>
                            Imprimir Recibo Conciliación - A4
                          </MenuItem>
                        )}
                      {configGeneral.ahorro_papel &&
                        arrayexistPdf.includes(item.id) && (
                          <MenuItem
                            onClick={() => printPdf(item.id, "A4Ahorro")}
                          >
                            Imprimir Recibo Conciliación - A4 - Ahorro de papel
                          </MenuItem>
                        )}
                    </MenuB>
                  </Badge>
                </TableCell>
              </TableRow>
            );
          })
        : null}
    </TableBody>
  );
}
