import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom/";
import ModalReutilizable from "../Modal";

export default function ModalValidacionClientCAE({
  idCliente,
  open,
  handleClose,
}) {
  const history = useHistory();

  const redirectClient = () => {
    history.push(`/clientes/modificar/${idCliente}`);
  };

  const getContentModal = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >
            El cliente seleccionado no tiene CUIT ni DNI cargado, por lo que no
            se puede generar el CAE de la factura. Complete los datos del
            cliente y reintente generar CAE desde el informe de ventas.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      title="Validación de CAE"
      content={getContentModal()}
      messageAceptar={"Editar Cliente"}
      messageCancelar={"Cancelar"}
      handleSubmit={redirectClient}
    />
  );
}
